import React from 'react'
import ReactDOM from 'react-dom'
import { AuthProvider } from 'hooks/useAuth'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { deepOrange, brown } from '@material-ui/core/colors'
import App from './App'

window.addEventListener('contextmenu', event => event.preventDefault())

const theme = createTheme({
  palette: {
    primary: { main: deepOrange[500] },
    secondary: { main: brown[500] },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
  },
})

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
