import { useCallback } from 'react'
import { usePinch } from 'hooks/usePinch'

export default function LineHeader({ lines }) {
  const { zoomX, setZoomX } = usePinch()

  const toggleZoomX = useCallback(() => {
    setZoomX(zoomX ? 0 : 1)
  }, [zoomX, setZoomX])

  return (
    <div style={{ display: 'flex', height: '100%' }} onClick={toggleZoomX}>
      {lines.map(line => (
        <div
          key={line.id}
          style={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            margin: '0px 1px 2px 1px',
            overflow: 'hidden',
          }}
        >
          <div
            style={{
              height: '100%',
              lineHeight: '12px',
              fontSize: 12,
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            <div
              style={{
                position: 'absolute',
                zIndex: 15,
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              {line.name}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
