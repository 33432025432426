export default function Logo() {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 32 }}>
      <img src="/icon.png" alt="PLUM CONNECT" style={{ width: 36, height: 32 }} />
      <div style={{ color: '#212121', fontSize: 28, fontFamily: '"Fira Sans", sans-seif', marginLeft: 12 }}>
        PLUM CONNECT
      </div>
    </div>
  )
}
