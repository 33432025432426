/* eslint no-restricted-globals: 0 */

import { useState, useEffect, useCallback } from 'react'

const defaultStorage = {}

export function useSharedState(key, initialState, storage) {
  const [value, _setValue] = useState(() => getSharedState(key, initialState, storage))
  const setValue = useCallback(value => setSharedState(key, value, storage), [key, storage])

  useEffect(() => {
    const onchange = event => _setValue(event.detail.value)
    self.addEventListener(`uss:${key}`, onchange)
    return () => self.removeEventListener(`uss:${key}`, onchange)
  }, [key])

  return [value, setValue]
}

export function getSharedState(key, initialState, storage) {
  if (typeof key !== 'string' || key === '') {
    throw new Error('identifier is required')
  }
  const store = storage || defaultStorage
  if (store[key] !== undefined) {
    try {
      return store instanceof Storage ? JSON.parse(store[key]) : store[key]
    } catch {}
  }
  if (initialState !== undefined) {
    const value = initialState instanceof Function ? initialState() : initialState
    store[key] = store instanceof Storage ? JSON.stringify(value) : value
    return value
  }
}

export function setSharedState(key, value, storage) {
  if (typeof key !== 'string' || key === '') {
    throw new Error('identifier is required')
  }
  const store = storage || defaultStorage
  store[key] = store instanceof Storage ? JSON.stringify(value) : value
  self.dispatchEvent(new CustomEvent(`uss:${key}`, { detail: { value } }))
}

export const useLocalState = (key, initialState) => useSharedState(key, initialState, localStorage)
export const getLocalState = (key, initialState) => getSharedState(key, initialState, localStorage)
export const setLocalState = (key, value) => setSharedState(key, value, localStorage)

export const useSessionState = (key, initialState) => useSharedState(key, initialState, sessionStorage)
export const getSessionState = (key, initialState) => getSharedState(key, initialState, sessionStorage)
export const setSessionState = (key, value) => setSharedState(key, value, sessionStorage)
