import { initializeApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getStorage, connectStorageEmulator } from 'firebase/storage'
import axios from 'axios'

const app = initializeApp({
  apiKey: 'AIzaSyDbPZXUKj1PJlcls-btg255TkUPJOOuwL8',
  authDomain: 'bamboo-connect.firebaseapp.com',
  projectId: 'bamboo-connect',
  storageBucket: 'bamboo-connect.appspot.com',
  messagingSenderId: '702211514764',
  appId: '1:702211514764:web:d16bb05c0f4d2c2b6af6a9',
  measurementId: 'G-SESWK5DDNR',
})

export const auth = getAuth(app)
export const functions = getFunctions(app, 'asia-northeast1')
export const firestore = getFirestore(app)
export const storage = getStorage(app)

// emulator settings
const host = process.env.REACT_APP_HOST || 'localhost'
if (process.env.NODE_ENV !== 'production') {
  connectAuthEmulator(auth, `http://${host}:9099`)
  connectFunctionsEmulator(functions, host, 5001)
  connectFirestoreEmulator(firestore, host, 8080)
  connectStorageEmulator(storage, host, 9199)
}

export const client = axios.create({
  baseURL:
    process.env.NODE_ENV !== 'production'
      ? `/${app.options.projectId}/asia-northeast1`
      : `https://asia-northeast1-${app.options.projectId}.cloudfunctions.net`,
})
