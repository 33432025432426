import { auth } from 'lib/firebase'
import { signOut } from 'firebase/auth'
import { Link, useLocation } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import AirlineSeatReclineExtraIcon from '@material-ui/icons/AirlineSeatReclineExtra'
import PeopleIcon from '@material-ui/icons/People'
import SettingsIcon from '@material-ui/icons/Settings'

export default function Side() {
  const location = useLocation()

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <div style={{ flexGrow: 1 }}>
        <div
          style={{
            width: 64,
            height: 64,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconButton component={Link} to="/">
            <img src="/icon.png" alt="plum" style={{ width: 30 }} />
          </IconButton>
        </div>
        <IconButton
          component={Link}
          to="/calendar"
          style={{ margin: 8 }}
          color={/^\/calendar(\/|$)/.test(location.pathname) ? 'primary' : 'default'}
        >
          <EventAvailableIcon />
        </IconButton>
        <IconButton
          component={Link}
          to="/seat"
          style={{ margin: 8 }}
          color={/^\/seat(\/|$)/.test(location.pathname) ? 'primary' : 'default'}
        >
          <AirlineSeatReclineExtraIcon />
        </IconButton>
        <IconButton
          component={Link}
          to="/shift"
          style={{ margin: 8 }}
          color={/^\/shift(\/|$)/.test(location.pathname) ? 'primary' : 'default'}
        >
          <PeopleIcon />
        </IconButton>
      </div>
      <div style={{ marginBottom: 16 }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <IconButton onClick={() => signOut(auth)}>
            <SettingsIcon />
          </IconButton>
        </div>
      </div>
    </Box>
  )
}
