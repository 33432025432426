import { FormProvider } from 'react-hook-form'
import Box from '@material-ui/core/Box'

export default function Form({ form, children, onSubmit, ...props }) {
  const custom = {
    className: 'Form',
    component: 'form',
    autoComplete: 'off',
    noValidate: true,
  }
  if (onSubmit) {
    custom.onSubmit = form.handleSubmit(onSubmit)
  }

  return (
    <FormProvider {...form}>
      <Box {...custom} {...props}>
        {children}
      </Box>
    </FormProvider>
  )
}
