import { useCalendar } from 'hooks/useCalendar'
import { auth } from 'lib/firebase'
import dayjs from 'dayjs'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

const weeks = ['日', '月', '火', '水', '木', '金', '土']

export default function Header() {
  const { date, setDate } = useCalendar()

  return (
    <>
      <Button sx={{ fontWeight: 'bold' }} onClick={() => setDate(dayjs().startOf('day'))} size="large">
        {date.format('YYYY年MM月DD日')}（{weeks[date.get('day')]}）
      </Button>
      <IconButton
        sx={{ p: '8px', display: { xs: 'none', sm: 'inherit' } }}
        onClick={() => setDate(date.add(-1, 'day'))}
      >
        <NavigateBeforeIcon />
      </IconButton>
      <IconButton sx={{ p: '8px', display: { xs: 'none', sm: 'inherit' } }} onClick={() => setDate(date.add(1, 'day'))}>
        <NavigateNextIcon />
      </IconButton>
      <Button
        sx={{ p: '8px', display: { xs: 'none', sm: 'inherit' } }}
        onClick={() => setDate(date.add(7, 'day'))}
        size="small"
      >
        翌週
      </Button>
      <div style={{ flexGrow: 1 }} />
      <IconButton onClick={() => auth.signOut()}>
        <SearchIcon />
      </IconButton>
    </>
  )
}
