import { useFormContext, useController } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import Switch from '@material-ui/core/Switch'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MenuItem from '@material-ui/core/MenuItem'

const defaultErrorText = {
  required: '必須項目です',
  pattern: '使用できない文字が含まれています',
}

export default function Field({
  name,
  rules = {},
  errorText,
  helperText,
  fullWidth = true,
  checkbox,
  radio,
  toggle,
  options,
  ...props
}) {
  const isFieldArray = name.indexOf('.') !== -1

  const {
    control,
    formState: { errors, isSubmitting },
  } = useFormContext()

  const defaultValue = isFieldArray
    ? name.split('.').reduce((acc, cur) => (acc ? acc[cur] : undefined), control.fieldArrayDefaultValuesRef.current)
    : control.defaultValuesRef.current[name]

  const {
    field: { ref, value, ...field },
  } = useController({ name, control, rules, defaultValue })

  const target = isFieldArray ? name.split('.').reduce((acc, cur) => acc[cur] || {}, errors) : errors[name]

  let errorMessage
  if (target) {
    if (typeof errorText === 'function') {
      errorMessage = errorText(target)
    } else if (typeof errorText === 'object' && errorText[target.type] !== undefined) {
      errorMessage = errorText[target.type]
    } else if (typeof errorText === 'string') {
      errorMessage = errorText
    } else if (defaultErrorText[target.type]) {
      errorMessage = defaultErrorText[target.type]
    } else {
      errorMessage = target.message
    }
  }

  props.disabled = props.disabled || isSubmitting

  if (checkbox || toggle) {
    const Control = checkbox ? Checkbox : Switch
    if (props.label) {
      return <FormControlLabel control={<Control />} inputRef={ref} checked={Boolean(value)} {...field} {...props} />
    } else {
      return <Control inputRef={ref} checked={Boolean(value)} {...field} {...props} />
    }
  }

  if (radio) {
    if (options) {
      props.children = options.map((x, i) => {
        const value = typeof x === 'string' ? x : x.value
        const label = typeof x === 'string' ? x : x.label
        return <FormControlLabel key={value} value={value} label={label} control={<Radio />} />
      })
    }
    return (
      <FormControl fullWidth className="Field" disabled={props.disabled}>
        <FormLabel>{props.label}</FormLabel>
        <RadioGroup value={value} {...field} {...props} />
      </FormControl>
    )
  }

  if (props.select && options) {
    props.children = options.map((x, i) => {
      const value = typeof x === 'string' ? x : x.value
      const label = typeof x === 'string' ? x : x.label
      return (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      )
    })
    props.select = Boolean(props.select)
  }

  return (
    <TextField
      className="Field"
      inputRef={ref}
      value={value !== undefined ? value : ''}
      error={errorMessage !== undefined}
      helperText={errorMessage || helperText}
      fullWidth={fullWidth}
      {...field}
      {...props}
    />
  )
}
