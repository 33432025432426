import { useCallback } from 'react'
import { setSharedState } from 'hooks/useSharedState'
import { useAuth } from 'hooks/useAuth'
import { auth } from 'lib/firebase'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { Link, Redirect } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Box from '@material-ui/core/Box'
import Form from 'components/Form'
import Field from 'components/Field'
import SubmitButton from 'components/SubmitButton'
import ErrorMessage from 'components/ErrorMessage'
import Logo from 'components/Logo'
import AuthLayout from './AuthLayout'

export default function SignInPage() {
  const { user } = useAuth()
  const form = useForm()

  const onSubmit = useCallback(
    ({ email, password }) => {
      return signInWithEmailAndPassword(auth, email, password)
        .then(({ user }) => setSharedState('snack', 'ようこそ' + (user.displayName || 'ゲスト') + 'さん'))
        .catch(err => {
          console.log(`\u001b[31m${err.code}`, err.message)
          if (err.code === 'auth/user-not-found') {
            form.setError('email', { message: 'このメールアドレスは登録されていません' })
          } else if (err.code === 'auth/wrong-password') {
            form.setError('password', { message: 'パスワードが違います' })
          } else if (err.code === 'auth/too-many-requests') {
            form.setError('_', { message: '連続してサインインに失敗したため、一時的にサインインが制限されています。' })
          } else if (err.code === 'auth/network-request-failed') {
            form.setError('_', { message: 'リクエストに失敗しました' })
          } else {
            form.setError('_', { message: 'リクエストに失敗しました' })
          }
        })
    },
    [form]
  )

  if (user) {
    return <Redirect to="/" />
  }

  return (
    <AuthLayout>
      <Form form={form} onSubmit={onSubmit} sx={{ '& .Field': { mb: '24px' } }}>
        <Logo />
        <Field name="email" type="email" label="メールアドレス" />
        <Field
          name="password"
          type="password"
          label="パスワード"
          rules={{
            required: true,
            minLength: 8,
            maxLength: 1024,
            pattern: /^[a-zA-Z0-9\-_.!"#$%&`‘*+,:;=?@^|~<>(){}[\]\/]+$/, // eslint-disable-line
          }}
          errorText={{
            minLength: 'パスワードは8文字以上を推奨しています',
            maxLength: 'パスワードが長すぎます',
          }}
        />
        <ErrorMessage name="_" sx={{ textAlign: 'center', color: '#f44336', mb: '8px', fontSize: '90%' }} />
        <SubmitButton sx={{ height: 56, fontSize: 16, fontWeight: 'bold' }}>サインイン</SubmitButton>
        <Box sx={{ mt: '16px', textAlign: 'center' }}>
          <span>アカウントをお持ちでない方は</span>
          <Link to="/signup">こちら</Link>
        </Box>
      </Form>
    </AuthLayout>
  )
}
