import { useFormContext, get } from 'react-hook-form'
import Box from '@material-ui/core/Box'

export default function ErrorMessage({ name, ...props }) {
  const {
    formState: { errors },
  } = useFormContext()

  const error = get(errors, name)

  if (error && error.message) {
    return <Box {...props}>{error.message}</Box>
  } else {
    return null
  }
}
