import { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Book from './Book'

const colors = [
  'rgb(255,0,0)',
  'rgb(244,255,88)',
  'rgb(255,0,128)',
  'rgba(0,0,0,0.2)',
  'rgba(0,0,0,0.2)',
  'rgba(0,0,0,0.2)',
  'rgba(0,0,0,0.2)',
  'rgba(0,0,0,0.2)',
]

const bgs = [
  'rgb(225, 225, 225)',

  'rgb(242, 245, 169)',
  'rgb(128, 255, 255)',
  'rgb(172, 249, 119)',
  'rgb(192, 192, 192)',
  'rgb(208, 250, 88)',

  'rgb(254, 46, 200)',
  'rgb(79, 195, 247)',
  'rgb(154, 46, 254)',
  'rgb(255, 128, 128)',

  'rgb(0, 128, 192)',
]

const sei = [
  '佐藤',
  '鈴木',
  '田中',
  '吉田',
  '伊藤',
  '高橋',
  '中山',
  '野間',
  '斎藤',
  '渡辺',
  '高山',
  '福島',
  '川口',
  '佐々木',
  '五十嵐',
]
const mei = ['太郎', '花子', '将太', '拓哉', '達也', '葵', '加奈子', '圭佑', '幸太郎', 'アリス']

export default function Line({ data, toggleSelected }) {
  const [books, setBooks] = useState([])

  useEffect(() => {
    const books = []
    for (let i = 0; i < Math.random() * 5; i++) {
      //XXX
      const name = [sei[Math.floor(Math.random() * sei.length)], mei[Math.floor(Math.random() * mei.length)]].join(' ')
      books.push({
        id: String(Math.floor(Math.random() * 100000000)),
        name,
        background: bgs[Math.floor(Math.random() * bgs.length)],
        boxShadow: 'inset 0 0 0 2px ' + colors[Math.floor(Math.random() * colors.length)],
        top: Math.floor(Math.random() * 80) + '%',
        height: Math.floor(Math.random() * 16) + 4 + '%',
      })
    }
    setBooks(books)
  }, [])

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        margin: '0px 1px 2px 1px',
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          width: '100%',
          background: '#ecf0f2',
          // boxShadow: 'inset 0px 2px 4px 0px rgba(0, 0, 0, 0.1)',
          wordBreak: 'break-all',
          position: 'relative',
        }}
      >
        <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
          <div style={{ width: '100%', position: 'relative', height: '100%' }}>
            {books.map(book => (
              <Book key={book.id} data={book} />
            ))}
          </div>
        </div>
      </Box>
    </Box>
  )
}
