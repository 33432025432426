import React, { useEffect, useReducer, useContext, createContext } from 'react'
import { auth } from 'lib/firebase'
import assign from 'lib/assign'
import { onAuthStateChanged } from 'firebase/auth'

const AuthContext = createContext()

export function AuthProvider({ children }) {
  const [state, setState] = useReducer(assign, { initialized: false, user: null })

  useEffect(() => {
    return onAuthStateChanged(auth, async user => {
      console.log('onAuthStateChanged', user)
      // WORKAROUND: user.emailVerified not updated when taking action in another tab
      if (user && !user.emailVerified) {
        await user.reload()
      }
      setState({ initialized: true, user })
    })
  }, [])

  return <AuthContext.Provider value={state} children={children} />
}

export function useAuth() {
  return useContext(AuthContext)
}
