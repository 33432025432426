import React, { useState, useEffect, useCallback, useRef, useContext, createContext } from 'react'

const PinchContext = createContext()
const SIDE_WIDTH = 48
const TOP_HEIGHT = 32

export function PinchProvider({ threshold = 10, tick = 0.1, children, ...props }) {
  const containerRef = useRef(null)
  const contentRef = useRef(null)
  const offsetRef = useRef({ zoomX: 0, zoomY: 0, dx: null, dy: null })
  const [zoomX, _setZoomX] = useState(0) // 0~1
  const [zoomY, _setZoomY] = useState(0) // 0~1

  const setZoomX = useCallback(zoomX => {
    offsetRef.current.zoomX = zoomX
    _setZoomX(zoomX)

    const container = containerRef.current
    const content = contentRef.current
    const offset = offsetRef.current

    const margin = SIDE_WIDTH - Math.min(container.scrollLeft, SIDE_WIDTH)
    const pos = (container.scrollLeft + offset.cx - margin) / (container.scrollWidth - margin * 2)
    content.style.width = 100 + 400 * zoomX + '%'
    container.scrollLeft = container.scrollWidth * pos - offset.cx
  }, [])

  const setZoomY = useCallback(zoomY => {
    offsetRef.current.zoomY = zoomY
    _setZoomY(zoomY)

    const container = containerRef.current
    const content = contentRef.current
    const offset = offsetRef.current

    const margin = TOP_HEIGHT // - Math.min(container.scrollTop, TOP_HEIGHT)
    const pos = (container.scrollTop + offset.cy - margin) / (container.scrollHeight - margin)
    content.style.height = 100 + 400 * zoomY + '%'
    container.scrollTop = (container.scrollHeight - margin) * pos - (offset.cy - margin)
  }, [])

  useEffect(() => {
    const container = containerRef.current
    const offset = offsetRef.current

    const handleTouchChange = event => {
      switch (event.touches.length) {
        case 1: {
          const { left, top } = container.getBoundingClientRect()
          offset.cx = event.touches[0].clientX - left
          offset.cy = event.touches[0].clientY - top
          return
        }
        case 2: {
          const { left, top } = container.getBoundingClientRect()
          offset.dx = Math.abs(event.touches[0].clientX - event.touches[1].clientX)
          offset.dy = Math.abs(event.touches[0].clientY - event.touches[1].clientY)
          offset.cx = (event.touches[0].clientX + event.touches[1].clientX) / 2 - left
          offset.cy = (event.touches[0].clientY + event.touches[1].clientY) / 2 - top
          return
        }
        default:
      }
    }

    const handleTouchMove = event => {
      if (event.touches.length !== 2) return

      if (event.cancelable) {
        event.stopPropagation()
        event.preventDefault()
      }

      const dx = Math.abs(event.touches[0].clientX - event.touches[1].clientX)
      const dy = Math.abs(event.touches[0].clientY - event.touches[1].clientY)

      if (Math.abs(dx - offset.dx) > threshold) {
        const zoomX = dx > offset.dx ? Math.min(1, offset.zoomX + tick) : Math.max(0, offset.zoomX - tick)
        setZoomX(Math.round(zoomX * 100) / 100)
        offset.dx = dx
      }

      if (Math.abs(dy - offset.dy) > threshold) {
        const zoomY = dy > offset.dy ? Math.min(1, offset.zoomY + tick) : Math.max(0, offset.zoomY - tick)
        setZoomY(Math.round(zoomY * 100) / 100)
        offset.dy = dy
      }
    }

    container.addEventListener('touchstart', handleTouchChange)
    container.addEventListener('touchend', handleTouchChange)
    container.addEventListener('touchmove', handleTouchMove)
    return () => {
      container.removeEventListener('touchstart', handleTouchChange)
      container.removeEventListener('touchend', handleTouchChange)
      container.removeEventListener('touchmove', handleTouchMove)
    }
  }, [threshold, tick, setZoomX, setZoomY])

  return (
    <PinchContext.Provider value={{ zoomX, zoomY, setZoomX, setZoomY }}>
      <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
        <div ref={containerRef} style={{ width: '100%', height: '100%', overflow: 'auto' }} className="scroller">
          <div ref={contentRef} style={{ width: '100%', height: '100%', position: 'relative' }}>
            {children}
          </div>
        </div>
      </div>
    </PinchContext.Provider>
  )
}

export function usePinch() {
  return useContext(PinchContext)
}
