import { CalendarProvider } from 'hooks/useCalendar'
import { PinchProvider } from 'hooks/usePinch'
import Layout from 'components/Layout'
import Header from './Header'
import Timetable from './Timetable'

export default function CalendarPage() {
  return (
    <CalendarProvider>
      <Layout header={<Header />}>
        <PinchProvider>
          <Timetable />
        </PinchProvider>
      </Layout>
    </CalendarProvider>
  )
}
