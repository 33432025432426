import { useMemo } from 'react'
import dayjs from 'dayjs'

export default function TimeBorder() {
  const times = useMemo(() => {
    const stTime = dayjs().startOf('day').add(8, 'hour')
    const edTime = dayjs().startOf('day').add(20, 'hour')
    const timeUnit = 30
    let current = dayjs(stTime)
    let limit = edTime.add(-timeUnit, 'minute')
    const minutes = edTime.diff(stTime, 'minute')
    const times = []
    do {
      if (current >= stTime) {
        times.push(
          <div
            key={current.toISOString()}
            style={{
              position: 'absolute',
              zIndex: 10,
              top: (current.diff(stTime, 'minute') / minutes) * 100 + '%',
              left: 0,
              right: 0,
              marginTop: -1,
              height: 2,
              background: 'rgba(255, 255, 255, .2)',
              boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.05), 0px -2px 8px 0px rgba(0, 0, 0, 0.05)',
              pointerEvents: 'none',
            }}
          />
        )
      }
      current = current.add(timeUnit, 'minute')
    } while (current <= limit)
    return times
  }, [])

  return times
}
