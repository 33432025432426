import Box from '@material-ui/core/Box'
import Side from './Side'

export default function Layout({ header, children }) {
  return (
    <Box sx={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex' }}>
      <Box
        sx={{
          width: 64,
          height: '100%',
          boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
          background: '#fff',
          zIndex: 10,
          display: { xs: 'none', sm: 'block' },
        }}
      >
        <Side />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          zIndex: 8,
        }}
      >
        <Box
          sx={{
            height: 64,
            boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.1)',
            background: '#fff',
            zIndex: 6,
            display: 'flex',
            alignItems: 'center',
            px: '16px',
          }}
        >
          {header}
        </Box>
        <Box sx={{ flexGrow: 1, zIndex: 4, position: 'relative' }}>{children}</Box>
      </Box>
    </Box>
  )
}
