import { useState, useEffect } from 'react'
import { useSharedState, setSharedState } from 'hooks/useSharedState'
import Snackbar from '@material-ui/core/Snackbar'
import Slide from '@material-ui/core/Slide'

export default function SharedSnackbar() {
  const [open, setOpen] = useState(false)
  const [snack, setSnack] = useSharedState('snack', '')

  useEffect(() => {
    setOpen(Boolean(snack))
  }, [snack])

  return (
    <Snackbar
      message={snack}
      open={open}
      onClose={() => setOpen(false)}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      TransitionComponent={Slide}
      TransitionProps={{ direction: 'down', onExited: () => setSnack('') }}
      sx={{ '&.MuiSnackbar-root': { top: '8px', pointerEvents: 'none' } }}
    />
  )
}
