import { useEffect } from 'react'
import { setSharedState } from 'hooks/useSharedState'
import { useAuth } from 'hooks/useAuth'
import { auth } from 'lib/firebase'
import { applyActionCode, verifyPasswordResetCode } from 'firebase/auth'
import Loading from 'components/Loading'

export default function ActionPage({ location, history }) {
  const { user } = useAuth()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const mode = params.get('mode')
    const actionCode = params.get('oobCode')
    // const continueUrl = params.get('continueUrl')

    if (mode === 'resetPassword') {
      verifyPasswordResetCode(auth, actionCode).then(email => {
        if (user && user.email !== email) {
          setSharedState('snack', '別のユーザーでサインイン中です')
        }
      })
      // confirmPasswordReset()
    } else if (mode === 'recoverEmail') {
      // TODO Implement email recovery
      history.replace('/')
    } else if (mode === 'verifyEmail') {
      applyActionCode(auth, actionCode)
        .then(() => {
          if (user) {
            setSharedState('snack', 'メールアドレスを認証しました')
            return user.reload()
          } else {
            setSharedState('snack', 'メールアドレスを認証しました。再度サインインをしてください。')
          }
        })
        .catch(err => {
          if (err.code === 'auth/expired-action-code') {
            setSharedState('snack', 'URLの有効期限が切れています')
          } else {
            setSharedState('snack', 'URLが無効です')
          }
        })
        .then(() => {
          history.replace('/')
        })
    } else {
      history.replace('/')
    }
  }, [user, location, history])

  return <Loading />
}
