import { useCallback } from 'react'
import { auth } from 'lib/firebase'
import { useAuth } from 'hooks/useAuth'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { Link, Redirect } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Box from '@material-ui/core/Box'
import Form from 'components/Form'
import Field from 'components/Field'
import SubmitButton from 'components/SubmitButton'
import ErrorMessage from 'components/ErrorMessage'
import Logo from 'components/Logo'
import AuthLayout from './AuthLayout'

export default function SignUpPage() {
  const { user } = useAuth()
  const form = useForm()

  const onSubmit = useCallback(
    ({ email, password }) => {
      return createUserWithEmailAndPassword(auth, email, password).catch(err => {
        console.log(`\u001b[31m${err.code}`, err.message)
        if (err.code === 'auth/email-already-in-use') {
          form.setError('email', { message: 'このメールアドレスは既に登録されています' })
        } else {
          form.setError('_', { message: 'リクエストに失敗しました' })
        }
      })
    },
    [form]
  )

  if (user) {
    return <Redirect to="/" />
  }

  return (
    <AuthLayout>
      <Form form={form} onSubmit={onSubmit} sx={{ '& .Field': { mb: '24px' } }}>
        <Logo />
        <Field name="email" type="email" label="メールアドレス" />
        <Field
          name="password"
          type="password"
          label="パスワード"
          rules={{
            required: true,
            minLength: 8,
            maxLength: 1024,
            pattern: /^[a-zA-Z0-9\-_.!"#$%&`‘*+,:;=?@^|~<>(){}[\]\/]+$/, // eslint-disable-line
          }}
          errorText={{
            minLength: 'パスワードは8文字以上を推奨しています',
            maxLength: 'パスワードが長すぎます',
          }}
        />
        <Field
          name="validatePassword"
          type="password"
          label="パスワード（確認）"
          rules={{ validate: x => x === form.getValues('password') }}
          errorText={{ validate: '同じパスワードを入力してください' }}
        />
        <Box sx={{ mb: '16px', textAlign: 'center' }}>
          <a href="/terms/" target="_blank">
            利用規約
          </a>
          <span>をご確認ください</span>
        </Box>
        <ErrorMessage name="_" sx={{ textAlign: 'center', color: '#f44336', mb: '8px', fontSize: '90%' }} />
        <SubmitButton sx={{ height: 56, fontSize: 16, fontWeight: 'bold' }}>利用規約に同意して新規登録</SubmitButton>
        <Box sx={{ mt: '16px', textAlign: 'center' }}>
          <span>アカウントを既にお持ちの方は</span>
          <Link to="/signin">こちら</Link>
        </Box>
      </Form>
    </AuthLayout>
  )
}
