import { useState, useCallback } from 'react'
import { Redirect } from 'react-router-dom'
import { auth } from 'lib/firebase'
import { sendEmailVerification } from 'firebase/auth'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import LoadingButton from 'components/LoadingButton'
import { ReactComponent as MailSent } from 'images/MailSent.svg'
import AuthLayout from './AuthLayout'

export default function EmailVerifyPage() {
  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)

  const handleVerify = useCallback(async () => {
    setLoading(true)
    await sendEmailVerification(auth.currentUser, { url: window.location.origin })
    setLoading(false)
    setSent(true)
  }, [])

  if (!auth.currentUser) {
    return <Redirect to="/signin" />
  }

  if (auth.currentUser.emailVerified) {
    return <Redirect to="/" />
  }

  return (
    <AuthLayout>
      <Dialog open={true} maxWidth="xs">
        <DialogTitle>メールアドレスの確認</DialogTitle>
        <DialogContent>
          <Box sx={{ px: 2, py: 1, background: '#eee', borderRadius: '4px' }}>{auth.currentUser.email}</Box>
          {!sent ? (
            <Box sx={{ mt: 1 }}>
              Plumを始めるには、このメールアドレスがご本人のものであることを確認する必要があります。
            </Box>
          ) : (
            <Box>
              <Box sx={{ display: 'grid', placeItems: 'center', my: '16px' }}>
                <MailSent style={{ maxWidth: 100, maxHeight: 100 }} />
              </Box>
              <Box>確認メールを送信しました。メール本文に記載されているURLより、ユーザー登録を完了させてください。</Box>
            </Box>
          )}
        </DialogContent>
        {!sent && (
          <DialogActions>
            <Button disabled={loading} onClick={() => auth.signOut()}>
              別のメールアドレスを使う
            </Button>
            <LoadingButton onClick={handleVerify} loading={loading}>
              メールアドレスを認証する
            </LoadingButton>
          </DialogActions>
        )}
      </Dialog>
    </AuthLayout>
  )
}
