import Box from '@material-ui/core/Box'
import Layout from 'components/Layout'

function SeatItem({ start, name, cid }) {
  return (
    <Box sx={{ m: '8px', p: '8px', background: '#eee', border: '2px solid #ddd', borderRadius: '4px' }}>
      <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
        <div style={{ flexGrow: 1 }}>{start}</div>
        <div>10分待</div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ flexGrow: 1 }}>{name}</div>
        <div>{cid}</div>
      </div>
    </Box>
  )
}

export default function SeatPage() {
  return (
    <Layout>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          display: 'flex',
          p: '4px',
          '& .panel': {
            background: '#fff',
            boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.1)',
            borderRadius: '4px',
            m: '4px',
            overflow: 'hidden',
          },
        }}
      >
        <Box className="panel" style={{ width: 240 }}>
          <SeatItem start="18:00" name="濱邉 将太" cid="23733" />
          <SeatItem start="18:30" name="梅木 博幸" cid="6501" />
        </Box>
        <Box className="panel" style={{ flexGrow: 1 }}>
          <img src="/seatsample.jpg" alt="座席表" style={{ width: '100%', display: 'block' }} />
        </Box>
      </Box>
    </Layout>
  )
}
