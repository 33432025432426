import { useCallback } from 'react'
import { auth } from 'lib/firebase'
import { useAuth } from 'hooks/useAuth'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { Redirect } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Form from 'components/Form'
import Field from 'components/Field'
import SubmitButton from 'components/SubmitButton'
import ErrorMessage from 'components/ErrorMessage'
import Logo from 'components/Logo'
import AuthLayout from './AuthLayout'

export default function PasswordResetPage({ location }) {
  const params = new URLSearchParams(location.search)
  console.log(params.get('email'))
  const { user } = useAuth()
  const form = useForm({
    defaultValues: {
      email: user ? user.email : params.get('email') ? params.get('email') : '',
    },
  })

  const onSubmit = useCallback(
    ({ email, password }) => {
      return createUserWithEmailAndPassword(auth, email, password).catch(err => {
        console.log(`\u001b[31m${err.code}`, err.message)
        if (err.code === 'auth/email-already-in-use') {
          form.setError('email', { message: 'このメールアドレスは既に登録されています' })
        } else {
          form.setError('_', { message: 'リクエストに失敗しました' })
        }
      })
    },
    [form]
  )

  // TODO Redirect if !user && !actionCode(valid)

  return (
    <AuthLayout>
      <Form form={form} onSubmit={onSubmit} sx={{ '& .Field': { mb: '24px' } }}>
        <Logo />
        <Field name="email" type="email" label="メールアドレス" />
        <Field
          name="newPassword"
          type="password"
          label="新しいパスワード"
          rules={{
            required: true,
            minLength: 8,
            maxLength: 1024,
            pattern: /^[a-zA-Z0-9\-_.!"#$%&`‘*+,:;=?@^|~<>(){}[\]\/]+$/, // eslint-disable-line
          }}
          errorText={{
            minLength: 'パスワードは8文字以上を推奨しています',
            maxLength: 'パスワードが長すぎます',
          }}
        />
        <ErrorMessage name="_" sx={{ textAlign: 'center', color: '#f44336', mb: '8px', fontSize: '90%' }} />
        <SubmitButton sx={{ height: 56, fontSize: 16, fontWeight: 'bold' }}>パスワードを変更する</SubmitButton>
      </Form>
    </AuthLayout>
  )
}
