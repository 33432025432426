import { useAuth } from 'hooks/useAuth'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import Loading from 'components/Loading'
import SharedSnackbar from 'components/SharedSnackbar'

import ActionPage from 'pages/auth/ActionPage'
import SignInPage from 'pages/auth/SignInPage'
import SignUpPage from 'pages/auth/SignUpPage'
import PasswordResetPage from 'pages/auth/PasswordResetPage'
import EmailVerifyPage from 'pages/auth/EmailVerifyPage'

import CalendarPage from 'pages/calendar'
import SeatPage from 'pages/seat'
import ShiftPage from 'pages/shift'
import SettingPage from 'pages/setting'

export default function App() {
  const { initialized } = useAuth()
  if (!initialized) {
    return <Loading />
  }

  return (
    <Router>
      <Switch>
        <Route exact path="/auth/action" component={ActionPage} />
        <Route exact path="/signin" component={SignInPage} />
        <Route exact path="/signup" component={SignUpPage} />
        <Route exact path="/password/reset" component={PasswordResetPage} />
        <Route exact path="/email/verify" component={EmailVerifyPage} />
        <PrivateRoute>
          <Route path="/calendar" component={CalendarPage} />
          <Route path="/seat" component={SeatPage} />
          <Route path="/shift" component={ShiftPage} />
          <Route path="/setting" component={SettingPage} />
          <Redirect to="/calendar" />
        </PrivateRoute>
      </Switch>
      <SharedSnackbar />
    </Router>
  )
}

function PrivateRoute({ location, children }) {
  const { user } = useAuth()

  if (!user) {
    const params = new URLSearchParams()
    params.set('redirect_to', location.pathname + location.search)
    return <Redirect to={'/signin?' + params} />
  }

  if (!user.emailVerified && location.pathname !== '/email/verify') {
    return <Redirect to="/email/verify" />
  }

  return <Switch>{children}</Switch>
}
