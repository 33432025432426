import Box from '@material-ui/core/Box'
import Layout from 'components/Layout'

export default function SettingPage() {
  return (
    <Layout>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          display: 'flex',
          p: '4px',
          '& .panel': {
            background: '#fff',
            boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.1)',
            borderRadius: '4px',
            m: '4px',
            overflow: 'hidden',
          },
        }}
      >
        <Box className="panel" style={{ width: 240 }}></Box>
        <Box className="panel" style={{ flexGrow: 1 }}>
          xxxxxxxxxxx
        </Box>
      </Box>
    </Layout>
  )
}
