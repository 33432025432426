import { useState, useEffect, useRef } from 'react'

export default function Book({ data }) {
  const ref = useRef(null)
  const [selected, setSelected] = useState(false)

  useEffect(() => {
    const target = ref.current
    let tid = null

    function ontouchstart(event) {
      console.log(event.touches.length)
      if (event.touches.length !== 1) {
        clearTimeout(tid)
        tid = null
        return
      }
      tid = setTimeout(() => {
        setSelected(true)
      }, 500)
    }
    function ontouchend() {
      clearTimeout(tid)
      tid = null
      setSelected(false)
    }
    target.addEventListener('touchstart', ontouchstart)
    target.addEventListener('touchend', ontouchend)
    return () => {
      target.removeEventListener('touchstart', ontouchstart)
      target.removeEventListener('touchend', ontouchend)
    }
  }, [])

  return (
    <div
      ref={ref}
      style={{
        position: 'absolute',
        zIndex: selected ? 11 : 10,
        left: 0,
        // right: 0,
        width: '100%',
        borderRadius: 4,
        overflow: 'hidden',
        fontSize: 10,
        lineHeight: '12px',
        letterSpacing: '0px',
        padding: 2,
        ...data,
        boxShadow: selected ? 'inset 0 0 0 3px #ff5722' : data.boxShadow,
      }}
    >
      <span style={{ whiteSpace: 'nowrap', marginRight: 4 }}>{data.name}</span>
      <span style={{ whiteSpace: 'nowrap' }}>{data.id}</span>
    </div>
  )
}
