import { useCalendar } from 'hooks/useCalendar'
import TimeBorder from './TimeBorder'
import TimeLabel from './TimeLabel'
import Line from './Line'
import LineHeader from './LineHeader'

const lines = [
  {
    id: 'xxx',
    name: '浜辺',
    books: [{ start: '2021-02-25T11:30', end: '2021-02-25T12:30' }],
  },
  {
    id: 'yyy',
    name: '小林',
    books: [],
  },
  {
    id: 'zzz',
    name: '梅木',
    books: [],
  },
]

for (let i = 0; i < 24; i++) {
  const names = [
    '佐藤',
    '鈴木',
    '田中',
    '吉田',
    '伊藤',
    '高橋',
    '中山',
    '野間',
    '斎藤',
    '渡辺',
    '高山',
    '福島',
    '川口',
    '佐々木',
    '五十嵐',
  ]
  lines.push({
    id: ('0000000' + Math.floor(Math.random() * 100000000)).slice(-8),
    name: names[Math.floor(Math.random() * names.length)],
    book: [],
  })
}

export default function Timetable() {
  const { date } = useCalendar()

  const timeLabelStyle = {
    width: 48,
    minWidth: 48,
    paddingTop: 32,
    position: 'sticky',
    zIndex: 13,
    background: '#fff',
  }

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', paddingBottom: 4, background: '#fff' }}>
      <div style={{ ...timeLabelStyle, left: 0 }}>
        <TimeLabel />
      </div>
      <div style={{ flexGrow: 1, display: 'flex', height: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'column', minWidth: '100%', position: 'relative' }}>
          <div style={{ height: 32, width: '100%', position: 'sticky', top: 0, zIndex: 12, background: '#fff' }}>
            <LineHeader lines={lines} />
          </div>
          <div style={{ flexGrow: 1, width: '100%', display: 'flex', position: 'relative' }}>
            {lines.map(line => (
              <Line key={`${line.id}:${date.format('YYYY-MM-DD')}`} data={line} />
            ))}
            <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
              <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                <TimeBorder />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ ...timeLabelStyle, right: 0 }}>
        <TimeLabel />
      </div>
    </div>
  )
}
