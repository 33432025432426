import React from 'react'
import Box from '@material-ui/core/Box'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import AirlineSeatReclineExtraIcon from '@material-ui/icons/AirlineSeatReclineExtra'
import PeopleIcon from '@material-ui/icons/People'

export default function AuthLayout({ children }) {
  const ListItem = ({ icon, title }) => (
    <div style={{ display: 'flex', alignItems: 'center', borderBottom: '2px solid #fff', marginBottom: 12 }}>
      {React.createElement(icon, { style: { width: 20, height: 20, marginRight: 8 } })}
      <div>{title}</div>
    </div>
  )

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          width: '100%',
          padding: '48px 24px',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          bgcolor: 'primary.main',
          color: '#fff',
          fontFamily: '"Fira Sans", sans-serif',
          height: '100%',
          display: { xs: 'none', md: 'flex' },
        }}
      >
        <div style={{ fontSize: 32 }}>PLUM CONNECT</div>
        <div style={{ fontSize: 24 }}>医科特化型マネージメントシステム</div>
        <div style={{ fontSize: 20, marginTop: 24 }}>
          <ListItem icon={EventAvailableIcon} title="PLUM 予約管理" />
          <ListItem icon={AirlineSeatReclineExtraIcon} title="PLUM 座席管理" />
          <ListItem icon={PeopleIcon} title="PLUM シフト管理" />
        </div>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          width: '100%',
          padding: '48px 24px',
          overflowX: 'hidden',
          overflowY: 'auto',
          WebkitOverflowScrolling: 'touch',
        }}
      >
        <Box sx={{ minHeight: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ maxWidth: 360 }}>{children}</Box>
        </Box>
      </Box>
    </Box>
  )
}
