import Box from '@material-ui/core/Box'
import Layout from 'components/Layout'
import Header from './Header'

export default function ShiftPage() {
  return (
    <Layout header={<Header />}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          display: 'flex',
          p: '4px',
        }}
      >
        <Box
          sx={{
            background: '#fff',
            boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.1)',
            borderRadius: '4px',
            m: '4px',
            overflow: 'hidden',
            flexGrow: 1,
            p: '16px',
          }}
        >
          This is ShiftPage
        </Box>
      </Box>
    </Layout>
  )
}
