import { useFormContext } from 'react-hook-form'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

export default function SubmitButton({ onSubmit, disabled, children, ...props }) {
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useFormContext()

  const custom = {
    disabled: disabled || isSubmitting,
    variant: 'contained',
    fullWidth: true,
  }

  if (onSubmit) {
    custom.onClick = handleSubmit(onSubmit)
  } else {
    custom.type = 'submit'
  }

  return (
    <Button {...custom} {...props}>
      {children}
      {isSubmitting && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress size={20} />
        </div>
      )}
    </Button>
  )
}
