import { useState, useContext, createContext } from 'react'
import dayjs from 'dayjs'

const CalendarContext = createContext()

export function CalendarProvider({ children }) {
  const [date, setDate] = useState(dayjs().startOf('day'))

  return <CalendarContext.Provider value={{ date, setDate }} children={children} />
}

export function useCalendar() {
  return useContext(CalendarContext)
}
