import Button from '@material-ui/core/Button'
import Loading from 'components/Loading'

export default function LoadingButton({ loading, disabled, children, ...props }) {
  return (
    <Button variant="contained" disabled={disabled || loading} {...props}>
      {children}
      {loading && <Loading size={20} />}
    </Button>
  )
}
