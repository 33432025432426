import { useMemo, useCallback } from 'react'
import { usePinch } from 'hooks/usePinch'
import dayjs from 'dayjs'

export default function TimeLabel() {
  const { zoomY, setZoomY } = usePinch()

  const toggleZoomY = useCallback(() => {
    setZoomY(zoomY ? 0 : 1)
  }, [zoomY, setZoomY])

  const times = useMemo(() => {
    const stTime = dayjs().startOf('day').add(8, 'hour')
    const edTime = dayjs().startOf('day').add(20, 'hour')
    const timeUnit = 30
    let current = dayjs(stTime)
    let limit = edTime.add(-timeUnit, 'minute')
    const minutes = edTime.diff(stTime, 'minute')
    const times = []
    do {
      if (current >= stTime) {
        const time = current.format('HH:mm')
        const style = {
          position: 'absolute',
          top: (current.diff(stTime, 'minute') / minutes) * 100 + '%',
          left: 0,
          right: 0,
          textAlign: 'center',
          fontSize: 12,
          lineHeight: '12px',
          pointerEvents: 'none',
        }
        times.push(
          <div key={`time_${time}`} style={style}>
            {time}
          </div>
        )
      }
      current = current.add(timeUnit, 'minute')
    } while (current <= limit)
    return times
  }, [])

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }} onClick={toggleZoomY}>
      {times}
    </div>
  )
}
